@import "../../style/mixin.scss";

.live-match-container {
  text-align: center;
  justify-items: center;
  .game-list {
    color: white;
  }

  .pulse-dot {
    width: 0.75rem;
    height: 0.75rem;
    animation: pulse 2s infinite;
  }

  .refresh-button {
    margin-top: 10px;
    padding-left: 10px;
    font-size: 0.8rem;
    cursor: pointer;
    
    &:hover {
      color: #1890ff;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .no-matches {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    color: #ffffff;
    font-size: 1.2rem;
  }

  .owl-carousel {
    .owl-stage-outer {
      padding: 10px 0;
    }

    .owl-nav {
      .owl-prev,
      .owl-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        background: #1890ff !important;
        color: #ffffff;
        border-radius: 50% !important;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
        
        span {
          font-size: 26px;
          color: #ffffff;
        }

        &.disabled {
          opacity: 0.5;
          cursor: default;
        }
      }

      .owl-prev {
        left: -20px;
      }

      .owl-next {
        right: -20px;
      }
    }
  }

  .match-card {
    height: 200px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: linear-gradient(135deg, #0d47a1 0%, #1976d2 100%);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 30%;
      left: -20%;
      width: 480px;
      height: 480px;
      background: radial-gradient(50% 50% at 50% 50%, rgba(25, 118, 210, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      z-index: 0;
    }

    .match-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      z-index: 1;

      .league-info {
        display: flex;
        align-items: center;
        gap: 8px;
        
        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }
        
        .league-name {
          font-size: 13px;
          line-height: 1.2;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 120px;
        }
      }

      .match-time {
        display: flex;
        align-items: center;
        gap: 8px;
        
        .live-badge {
          background-color: #FF0402;
          border-radius: 3px;
          padding: 1px 4px;
          font-size: 10px;
          font-weight: 600;
          color: #FFFFFF;
        }
        
        .time {
          font-size: 12px;
          white-space: nowrap;
        }
      }
    }

    .teams-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px;
      flex: 1;
      z-index: 1;

      .team {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 0 0 40%;
        
        .team-logo {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.1);
          margin-bottom: 8px;
          
          img {
            width: 34px;
            height: 34px;
            object-fit: contain;
          }
        }
        
        .team-name {
          text-align: center;
          font-size: 0.875rem;
          font-weight: 600;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .vs {
        font-size: 0.875rem;
        font-weight: 700;
      }
    }

    .match-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      gap: 10px;
      z-index: 1;

      .score {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 32px;
        border-radius: 4px;
        padding: 0.5rem;
        background-color: rgba(0, 0, 0, 0.2);
        font-weight: 600;
      }

      .bet-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 32px;
        font-size: 0.75rem;
        font-weight: 600;
        border-radius: 4px;
        padding: 0.5rem;
        cursor: pointer;
        background: linear-gradient(165.34deg, #FF0402 -17.16%, #7A000C 91.36%);
        box-shadow: 0 0 7px #80000D;
        transition: all 0.3s ease;
        
        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 2px 10px #80000D;
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

@media (max-width: 767px) {
  .live-match-container {
    .match-card {
      height: 180px;
      
      .match-header {
        padding: 8px;
        
        .league-info {
          .league-name {
            max-width: 80px;
          }
        }
      }
      
      .teams-container {
        padding: 5px;
        
        .team {
          .team-logo {
            width: 36px;
            height: 36px;
            
            img {
              width: 30px;
              height: 30px;
            }
          }
          
          .team-name {
            font-size: 0.75rem;
          }
        }
      }
      
      .match-footer {
        padding: 8px;
      }
    }
  }
}
